import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Breadcrumbs,
    Card,
    CardContent,
    Divider,
    LinearProgress,
    Link,
    List,
    ListItem,
    ListItemText,
    Stack,
    TextField,
    Tooltip,
    useMediaQuery
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {useNavigate, useParams} from "react-router-dom";
import ColorBoxes from "../ColorBoxes";
import {
    API_BASE_URL,
    IMG_PREVIEW_URL,
    SINGLE_COLLECTOR_PATH,
    SINGLE_TOKEN_PATH,
    TOKENS_PATH,
    TOKENS_URL,
    traits
} from "../../utils/constants";
import Typography from "@mui/material/Typography";
import styled from "@mui/system/styled";
import {Token} from "../../models/token";
import PaperToottip from "./tooltips/PaperToottip";
import MarginsTooltip from "./tooltips/MarginsTooltip";
import FormatTooltip from "./tooltips/FormatTooltip";
import BackgroundTooltip from "./tooltips/BackgroundTooltip";
import TreeChoiceTooltip from "./tooltips/TreeChoiceTooltip";
import TextureTooltip from "./tooltips/TextureTooltip";
import MentionTooltip from "./tooltips/MentionTooltip";
import BarkTooltip from "./tooltips/BarkTooltip";
import ThreadFlowTooltip from "./tooltips/ThreadFlowTooltip";
import ThreadTooltip from "./tooltips/ThreadTooltip";
import {cutTheMiddle, formatEth, snakeToCamelCase} from "../../utils/helpers";
import {ApiResponse} from "../../models/ApiResponse";
import TokenTransactionsPage from "./TokenTransactionsPage";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import theme from "../../theme";

const ListItemTokenParams = styled(ListItem)(() => ({
    paddingTop: 1,
    paddingBottom: 1,
}));

const ListItemTokenTraits = styled(ListItem)(() => ({
    paddingTop: 0,
    paddingBottom: 0,
}));


export default function SingleTokenPage() {
    const {tokenId, section} = useParams();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [currentTokenId, setCurrenTokenId] = useState(Number(tokenId));
    const [token, setToken] = useState<Token>();
    const showTransactions = useMemo(() => {
        return section === "transactions"
    }, [section]);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        if (tokenId != null) {
            fetch(`${API_BASE_URL}${TOKENS_URL}/${currentTokenId}`)
                .then(response => response.json())
                // @ts-ignore:next-line
                .then((response: ApiResponse<Token>) => setToken(snakeToCamelCase(response.data)))
                .catch(e => console.error(e)).finally(() => setIsLoading(false))
        }
    }, [tokenId]);

    const handleTokenIdSubmit = () => {
        if (currentTokenId > 76208) return true;
        navigate(`/${SINGLE_TOKEN_PATH}${currentTokenId}`)
    };

    const handleTokenIdChange = (value: string) => {
        setCurrenTokenId(Number(value) || 0);
    };

    return (
        <>
            <Grid container spacing={3} height={'100%'} sx={{position: 'relative', overflow: 'hidden', height: '10px'}}>
                <ColorBoxes maxHeight={10}/>
            </Grid>
            <Card>
                <CardContent>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/">
                            Home
                        </Link>
                        <Link underline="hover" color="inherit" href={`/${TOKENS_PATH}`}>
                            Tokens
                        </Link>
                        <Typography sx={{color: 'text.primary'}}>Aux Arbres #{tokenId}</Typography>
                    </Breadcrumbs>

                    <Grid container spacing={2}>
                        <Grid size={{xs: 12, sm: 6, md: 4}}>
                            <Card variant="outlined" sx={{maxWidth: 360}}>
                                <Box sx={{paddingTop: 2, paddingLeft: 2, paddingRight: 2}}>
                                    <Stack
                                        direction="row"
                                        sx={{
                                            justifyContent: 'left',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Typography gutterBottom variant="h6" sx={{width: "130px"}}>
                                            Aux Arbres
                                        </Typography>
                                        <Typography gutterBottom variant="h6" component={'span'}>
                                            <strong>#&nbsp;</strong>
                                        </Typography>
                                        <TextField
                                            id="standard-start-adornment"
                                            sx={{m: 0, width: '70px'}}
                                            variant="standard"
                                            value={`${currentTokenId}`}
                                            inputProps={{style: {fontSize: "1.3rem"}}}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTokenIdChange(event.target.value)}
                                            onKeyDown={(ev) => {
                                                if (ev.key === 'Enter') {
                                                    handleTokenIdSubmit();
                                                    ev.preventDefault();
                                                }
                                            }}
                                        />
                                    </Stack>
                                </Box>
                                <Divider/>

                                <Box sx={{paddingLeft: 2, paddingRight: 2}}>
                                    <List sx={{paddingTop: 1}}>
                                        <ListItemTokenParams disableGutters>
                                            <ListItemText primary={`Owner: `} sx={{width: "40px"}}/>
                                            <Typography sx={{flex: "2"}}><Link
                                                href={"/" + SINGLE_COLLECTOR_PATH + token?.owner?.wallet}>{token && cutTheMiddle(token?.owner?.wallet)}</Link> ({token && String(token.owner.totalTokens)})</Typography>
                                        </ListItemTokenParams>

                                        <ListItemTokenParams disableGutters>
                                            <ListItemText primary={`Trades: `} sx={{width: "40px"}}/>
                                            <Typography sx={{flex: "2"}}>{token && token.numTransactions} <Link
                                                href={`/${SINGLE_TOKEN_PATH}${tokenId}/transactions`}> (see
                                                transactions)</Link>
                                            </Typography>
                                        </ListItemTokenParams>

                                        <ListItemTokenParams disableGutters>
                                            <ListItemText primary={`Dimentions: `} sx={{width: "40px"}}/>
                                            <Typography sx={{flex: "2"}}>{token?.height} x {token?.width} px
                                            </Typography>
                                        </ListItemTokenParams>

                                        <ListItemTokenParams disableGutters>
                                            <ListItemText primary={`Value: `} sx={{width: "40px"}}/>
                                            <Typography
                                                sx={{flex: "2"}}>{token?.lastPrice && `${formatEth(token?.lastPrice)}` || 'never been sold'} </Typography>
                                        </ListItemTokenParams>
                                    </List>
                                </Box>
                                <Divider/>


                                {/*--------------------------------------- TRAITS*/}
                                <Accordion defaultExpanded={!isMobile} disableGutters>
                                    <AccordionSummary
                                        sx={{minHeight: "0px", ".MuiAccordionSummary-content": {margin: 0, padding:0},".MuiAccordionSummary-gutters ": {margin: 0, padding:0}}}
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography gutterBottom variant="h6">
                                            Traits
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>

                                        <Box sx={{p: 2}}>
                                            <List sx={{paddingTop: 0}}>
                                                <ListItemTokenTraits disableGutters>
                                                    <ListItemText primary='Tree id: ' sx={{width: "40px"}}/>
                                                    {/*should we list all tokens for a given tree id?*/}
                                                    <Typography sx={{flex: "2"}}>{token?.treeId}</Typography>
                                                </ListItemTokenTraits>

                                                <Tooltip
                                                    title={<TreeChoiceTooltip selectedTreeChoice={token?.treeChoice}/>}
                                                    arrow
                                                    slotProps={{
                                                        popper: {
                                                            modifiers: [
                                                                {
                                                                    name: 'offset',
                                                                    options: {
                                                                        offset: [0, -14],
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    }}
                                                >
                                                    <ListItemTokenTraits disableGutters alignItems="flex-start">
                                                        <ListItemText primary='Tree choice: ' sx={{width: "40px"}}/>
                                                        <ListItemText primary={token?.treeChoice}
                                                                      secondary={<Box>
                                                                          {(traits.tree_choice.find(trait => trait.value === token?.treeChoice))?.rarity.label}
                                                                          <LinearProgress
                                                                              sx={{backgroundColor: (traits.tree_choice.find(trait => trait.value === token?.treeChoice))?.rarity.color,}}
                                                                              value={0}
                                                                              variant={"determinate"}/>
                                                                      </Box>}
                                                                      sx={{flex: "2"}}/>
                                                        <LinearProgress/>
                                                    </ListItemTokenTraits>
                                                </Tooltip>

                                                <ListItemTokenTraits disableGutters>
                                                    <ListItemText primary='Coordinates: ' sx={{width: "40px"}}/>
                                                    <Typography sx={{flex: "2"}}>{token?.coordinates}</Typography>
                                                </ListItemTokenTraits>

                                                <Tooltip
                                                    title={<PaperToottip selectedPaper={token?.paper}/>}
                                                    arrow
                                                    slotProps={{
                                                        popper: {
                                                            modifiers: [
                                                                {
                                                                    name: 'offset',
                                                                    options: {
                                                                        offset: [0, -14],
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    }}
                                                >
                                                    <ListItemTokenTraits disableGutters alignItems="flex-start">
                                                        <ListItemText primary='Paper: ' sx={{width: "40px"}}/>
                                                        <ListItemText primary={token?.paper}
                                                                      secondary={<Box>
                                                                          {(traits.paper.find(trait => trait.value === token?.paper))?.rarity.label}
                                                                          <LinearProgress
                                                                              sx={{backgroundColor: (traits.paper.find(trait => trait.value === token?.paper))?.rarity.color,}}
                                                                              value={0}
                                                                              variant={"determinate"}/>
                                                                      </Box>} sx={{flex: "2"}}/>
                                                    </ListItemTokenTraits>
                                                </Tooltip>

                                                <Tooltip
                                                    title={<MarginsTooltip selectedMargins={token?.margins}/>}
                                                    arrow
                                                    slotProps={{
                                                        popper: {
                                                            modifiers: [
                                                                {
                                                                    name: 'offset',
                                                                    options: {
                                                                        offset: [0, -14],
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    }}
                                                >
                                                    <ListItemTokenTraits disableGutters alignItems="flex-start">
                                                        <ListItemText primary='Margins: ' sx={{width: "40px"}}/>
                                                        <ListItemText primary={token?.margins}
                                                                      secondary={<Box>
                                                                          {(traits.margins.find(trait => trait.value === token?.margins))?.rarity.label}
                                                                          <LinearProgress
                                                                              sx={{backgroundColor: (traits.margins.find(trait => trait.value === token?.margins))?.rarity.color,}}
                                                                              value={0}
                                                                              variant={"determinate"}/>
                                                                      </Box>} sx={{flex: "2"}}/>
                                                    </ListItemTokenTraits>
                                                </Tooltip>

                                                <Tooltip
                                                    title={<FormatTooltip selectedFormat={token?.format}/>}
                                                    arrow
                                                    slotProps={{
                                                        popper: {
                                                            modifiers: [
                                                                {
                                                                    name: 'offset',
                                                                    options: {
                                                                        offset: [0, -14],
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    }}
                                                >
                                                    <ListItemTokenTraits disableGutters alignItems="flex-start">
                                                        <ListItemText primary='Format: ' sx={{width: "40px"}}/>
                                                        <ListItemText primary={token?.format}
                                                                      secondary={<Box>
                                                                          {(traits.format.find(trait => trait.value === token?.format))?.rarity.label}
                                                                          <LinearProgress
                                                                              sx={{backgroundColor: (traits.format.find(trait => trait.value === token?.format))?.rarity.color,}}
                                                                              value={0}
                                                                              variant={"determinate"}/>
                                                                      </Box>} sx={{flex: "2"}}/>
                                                    </ListItemTokenTraits>
                                                </Tooltip>

                                                <Tooltip
                                                    title={<BackgroundTooltip selectedBackground={token?.background}/>}
                                                    arrow
                                                    slotProps={{
                                                        popper: {
                                                            modifiers: [
                                                                {
                                                                    name: 'offset',
                                                                    options: {
                                                                        offset: [0, -14],
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    }}
                                                >
                                                    <ListItemTokenTraits disableGutters alignItems="flex-start">
                                                        <ListItemText primary='Background: ' sx={{width: "40px"}}/>
                                                        <ListItemText primary={token?.background}
                                                                      secondary={<Box>
                                                                          {(traits.background.find(trait => trait.value === token?.background))?.rarity.label}
                                                                          <LinearProgress
                                                                              sx={{backgroundColor: (traits.background.find(trait => trait.value === token?.background))?.rarity.color,}}
                                                                              value={0}
                                                                              variant={"determinate"}/>
                                                                      </Box>} sx={{flex: "2"}}/>
                                                    </ListItemTokenTraits>
                                                </Tooltip>

                                                <Tooltip
                                                    title={<TextureTooltip selectedTexture={token?.texture}/>}
                                                    arrow
                                                    slotProps={{
                                                        popper: {
                                                            modifiers: [
                                                                {
                                                                    name: 'offset',
                                                                    options: {
                                                                        offset: [0, -14],
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    }}
                                                >
                                                    <ListItemTokenTraits disableGutters alignItems="flex-start">
                                                        <ListItemText primary='Texture: ' sx={{width: "40px"}}/>
                                                        <ListItemText primary={token?.texture}
                                                                      secondary={<Box>
                                                                          {(traits.texture.find(trait => trait.value === token?.texture))?.rarity.label}
                                                                          <LinearProgress
                                                                              sx={{backgroundColor: (traits.texture.find(trait => trait.value === token?.texture))?.rarity.color,}}
                                                                              value={0}
                                                                              variant={"determinate"}/>
                                                                      </Box>} sx={{flex: "2"}}/>
                                                    </ListItemTokenTraits>
                                                </Tooltip>

                                                <Tooltip
                                                    title={<MentionTooltip selectedMention={token?.mention}/>}
                                                    arrow
                                                    slotProps={{
                                                        popper: {
                                                            modifiers: [
                                                                {
                                                                    name: 'offset',
                                                                    options: {
                                                                        offset: [0, -14],
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    }}
                                                >
                                                    <ListItemTokenTraits disableGutters alignItems="flex-start">
                                                        <ListItemText primary='Mention: ' sx={{width: "40px"}}/>
                                                        <ListItemText primary={token?.mention}
                                                                      secondary={<Box>
                                                                          {(traits.mention.find(trait => trait.value === token?.mention))?.rarity.label}
                                                                          <LinearProgress
                                                                              sx={{backgroundColor: (traits.mention.find(trait => trait.value === token?.mention))?.rarity.color,}}
                                                                              value={0}
                                                                              variant={"determinate"}/>
                                                                      </Box>}
                                                                      sx={{flex: "2"}}/>
                                                    </ListItemTokenTraits>
                                                </Tooltip>

                                                <Tooltip
                                                    title={<BarkTooltip selectedBark={token?.bark}/>}
                                                    arrow
                                                    slotProps={{
                                                        popper: {
                                                            modifiers: [
                                                                {
                                                                    name: 'offset',
                                                                    options: {
                                                                        offset: [0, -14],
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    }}
                                                >
                                                    <ListItemTokenTraits disableGutters alignItems="flex-start">
                                                        <ListItemText primary='Bark: ' sx={{width: "40px"}}/>
                                                        <ListItemText primary={token?.bark}
                                                                      secondary={<Box>
                                                                          {(traits.bark.find(trait => trait.value === token?.bark))?.rarity.label}
                                                                          <LinearProgress
                                                                              sx={{backgroundColor: (traits.bark.find(trait => trait.value === token?.bark))?.rarity.color,}}
                                                                              value={0}
                                                                              variant={"determinate"}/>
                                                                      </Box>}
                                                                      sx={{flex: "2"}}/>
                                                    </ListItemTokenTraits>
                                                </Tooltip>

                                                <Tooltip
                                                    title={<ThreadFlowTooltip selectedThreadFlow={token?.threadFlow}/>}
                                                    arrow
                                                    slotProps={{
                                                        popper: {
                                                            modifiers: [
                                                                {
                                                                    name: 'offset',
                                                                    options: {
                                                                        offset: [0, -14],
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    }}
                                                >
                                                    <ListItemTokenTraits disableGutters alignItems="flex-start">
                                                        <ListItemText primary='Thread flow: ' sx={{width: "40px"}}/>
                                                        <ListItemText primary={token?.threadFlow}
                                                                      secondary={<Box>
                                                                          {(traits.thread_flow.find(trait => trait.value === token?.threadFlow))?.rarity.label}
                                                                          <LinearProgress
                                                                              sx={{backgroundColor: (traits.thread_flow.find(trait => trait.value === token?.threadFlow))?.rarity.color,}}
                                                                              value={0}
                                                                              variant={"determinate"}/>
                                                                      </Box>}
                                                                      sx={{flex: "2"}}/>
                                                    </ListItemTokenTraits>
                                                </Tooltip>

                                                <Tooltip
                                                    title={<ThreadTooltip selectedThread={token?.thread}/>}
                                                    arrow
                                                    slotProps={{
                                                        popper: {
                                                            modifiers: [
                                                                {
                                                                    name: 'offset',
                                                                    options: {
                                                                        offset: [0, -14],
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    }}
                                                >
                                                    <ListItemTokenTraits disableGutters alignItems="flex-start">
                                                        <ListItemText primary='Thread: ' sx={{width: "40px"}}/>
                                                        <ListItemText primary={token?.thread}
                                                                      secondary={
                                                                          <Box>
                                                                              {(traits.thread.find(trait => trait.value === token?.thread))?.rarity.label}
                                                                              <LinearProgress
                                                                                  sx={{backgroundColor: (traits.thread.find(trait => trait.value === token?.thread))?.rarity.color,}}
                                                                                  value={0}
                                                                                  variant={"determinate"}/>
                                                                          </Box>}
                                                                      sx={{flex: "2"}}
                                                        />
                                                    </ListItemTokenTraits>
                                                </Tooltip>

                                            </List>
                                        </Box>
                                        <Divider/>


                                        <Box sx={{p: 2}}>
                                            <Typography gutterBottom variant="h6">
                                                Rarity
                                            </Typography>
                                            <List sx={{paddingTop: 0}}>
                                                <ListItemTokenParams disableGutters>
                                                    <Typography>Here we will try to indicate what is notable
                                                        about
                                                        this
                                                        token.</Typography>
                                                </ListItemTokenParams>
                                            </List>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            </Card>


                        </Grid>
                        <Grid size={{xs: 12, sm: 6, md: 8}}>
                            {showTransactions ? <TokenTransactionsPage/> :
                                isLoading ? <LinearProgress/> : <Box component="img"
                                                                     width={"100%"}
                                                                     src={`${IMG_PREVIEW_URL}${token?.id}.jpg`}
                                                                     alt={`Aux Arbres token #${tokenId}`}
                                                                     sx={{objectFit: "contain"}}/>
                            }
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
        </>
    );
}
