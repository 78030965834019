import {BigNumberish, ethers} from "ethers";
import {TransactionsTimeseriesUnit} from "../models/transactionsTimeseriesUnit";

export function snakeToCamelCase(obj: any): any {
    if (Array.isArray(obj)) {
        return obj.map(item => snakeToCamelCase(item));
    } else if (obj !== null && typeof obj === 'object') {
        return Object.fromEntries(
            Object.entries(obj).map(([key, value]) => {
                const newKey = key.replace(/(_\w)/g, (m) => m[1].toUpperCase());
                return [newKey, snakeToCamelCase(value)];
            })
        );
    }
    return obj;
}

export function cutTheMiddle(str: string | undefined) {
    if (str != null) {
        return str.substr(0, 5) + '...' + str.substr(str.length - 5, str.length);
    }
    return str;
}

export function formatEth(weiAmount: BigNumberish): string {
    if (weiAmount == 0) return "0";

    return ethers.formatUnits(weiAmount, 18) + ' Eth';
}

export function transformTransactionTimeseries(data: any[]): TransactionsTimeseriesUnit[] {
    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    return data.map((unit, index) => {
        return {
            ...unit,
            week: index,
            month: month[new Date(unit.date).getMonth()],
            volume: Number(unit.volume),
            formattedVolume: formatEth(BigInt(unit.volume))
        } as TransactionsTimeseriesUnit;
    });
}