import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import {Box, Switch} from "@mui/material";
import {Transaction} from "../../models/transaction";
import {useParams, useSearchParams} from "react-router-dom";
import {
    API_BASE_URL,
    IMG_PREVIEW_URL,
    SINGLE_COLLECTOR_PATH,
    SINGLE_TOKEN_PATH,
    TRANSACTIONS_URL
} from "../../utils/constants";
import {ApiResponse} from "../../models/ApiResponse";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {GridColDef, GridRenderCellParams, GridSortModel} from '@mui/x-data-grid';
import {cutTheMiddle, formatEth} from "../../utils/helpers";
import PaginatedTable from "../common/PaginatedTable";
import Button from "@mui/material/Button";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function TokenTransactionsPage() {

    const {tokenId} = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [loadedTransactions, setLoadedTransactions] = useState<Transaction[]>([]);

    const [searchParams, setSearchParams] = useSearchParams();
    const isOnlySales = useMemo(() => {
        return Boolean(searchParams.get('sales_only'));
    }, [searchParams]);

    const sortModel = useMemo<GridSortModel | undefined>(() => {
        const orderParam = searchParams.get('order_by') || '-timestamp';
        if (orderParam == null) return;
        const order = orderParam.substring(0, 1) === '-' ? 'desc' : 'asc'
        return [{field: order === "asc" ? orderParam : orderParam.substring(1, orderParam.length), sort: order}];
    }, [searchParams]);

    const paginationModel = useMemo<{ pageSize: number; page: number; }>(() => {
        return {
            pageSize: Number(searchParams.get('perPage')) || 25,
            page: parseInt(searchParams.get('page') || '0', 10),
        }
    }, [searchParams]);

    useEffect(() => {
        function getOrderByParam() {
            if (sortModel == null || sortModel.length == 0) return "";
            const orderBy = sortModel[0].sort === "asc" ? sortModel[0].field : "-" + sortModel[0].field;
            return `&order_by=${orderBy}`;
        }

        const isOnlySalesParam: string = isOnlySales ? "&sales_only=true" : "";
        setIsLoading(true);

        const offset = `offset=${paginationModel.page * paginationModel.pageSize}`;
        const limit = `&limit=${paginationModel.pageSize}`;
        const orderByParam = getOrderByParam();
        fetch(`${API_BASE_URL}${TRANSACTIONS_URL}?${offset}&token_id=${tokenId}${orderByParam}${limit}${isOnlySalesParam}`)
            .then(response => response.json())
            .then((response: ApiResponse<Transaction[]>) => {
                setTotalRowCount(Number(response.pagination.totalRowCount));
                setLoadedTransactions(response.data.map((tr) => {
                    return {...tr, timestamp: new Date(tr.timestamp)}
                }));
                setIsLoading(false);
            });
    }, [paginationModel.page, paginationModel.pageSize, sortModel, isOnlySales]);

    const handleIsOnlySalesChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.checked) {
            searchParams.delete("sales_only");
        } else {
            searchParams.set("sales_only", String(event.target.checked));
        }
        searchParams.delete("page")
        setSearchParams(searchParams);
    };


    const columns: GridColDef<(typeof loadedTransactions)[number]>[] = [
        {
            field: 'id',
            headerName: ' id',
            width: 30,
        },
        {
            field: 'sender',
            headerName: 'Sender',
            width: 100,
            editable: false,
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, any>) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'left',
                    }}
                >
                    <a href={`/${SINGLE_COLLECTOR_PATH}${params.row.sender.wallet}`}>
                        {`${cutTheMiddle(params.row.sender.wallet) || ''}`}
                    </a>
                </Box>
            ),
        },
        {
            field: 'received',
            headerName: 'Receiver',
            width: 100,
            editable: false,
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, any>) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'left',
                    }}
                >
                    <a href={`/${SINGLE_COLLECTOR_PATH}${params.row.receiver.wallet}`}>
                        {`${cutTheMiddle(params.row.receiver.wallet) || ''}`}
                    </a>
                </Box>
            ),
        },
        {
            field: 'timestamp',
            headerName: 'Date',
            sortable: true,
            flex: 1,
            width: 160,
            valueGetter: (_value: any, row: Transaction) => row.timestamp.toLocaleDateString() + ' ' + row.timestamp.toLocaleTimeString(),
        },
        {
            field: 'price',
            headerName: 'Amount',
            sortable: true,
            flex: 1,
            width: 160,
            valueGetter: (_value: any, row: Transaction) => formatEth(BigInt(row.price || 0)),
        },
    ];

    return (
        <>
            <Paper variant={"outlined"}
                   sx={{
                       display: 'flex',
                       justifyContent: 'flex-start',
                       flexWrap: 'wrap',
                       listStyle: 'none',
                       flexGrow: 1,
                       p: 0.5,
                       m: 0,
                       alignItems: "center",
                   }}
            >
                <Button variant="outlined" startIcon={<ArrowBackIcon/>} href={`/${SINGLE_TOKEN_PATH}${tokenId}`}>
                    <img
                        alt="token preview"
                        height={50}
                        src={`${IMG_PREVIEW_URL}${tokenId}.jpg`}
                        loading="lazy"
                    />
                </Button>

                <Typography variant={"subtitle1"} paddingLeft={"50px"}>Only sales</Typography> <Switch
                checked={isOnlySales}
                onChange={handleIsOnlySalesChanged}
                inputProps={{'aria-label': 'controlled'}}
            />
            </Paper>

            <PaginatedTable columns={columns}
                            data={loadedTransactions}
                            isLoading={isLoading}
                            paginationModel={paginationModel}
                            sortModel={sortModel}
                            totalRowCount={totalRowCount}
            />

            <br/>
        </>
    );
}
