import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import {Box, Breadcrumbs, Card, CardContent, Link, List, ListItem, ListItemText, Tab, Tabs} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {useNavigate, useParams} from "react-router-dom";
import ColorBoxes from "../ColorBoxes";
import {API_BASE_URL, COLLECTORS_PATH, COLLECTORS_URL, SINGLE_COLLECTOR_PATH} from "../../utils/constants";
import Typography from "@mui/material/Typography";
import TokensTab from "./TokensTab";
import TransactionsTab from "./TransactionsTab";
import {formatEth, snakeToCamelCase} from "../../utils/helpers";
import {ApiResponse} from "../../models/ApiResponse";
import {Collector} from "../../models/collector";

const TOKENS = "tokens";
const TRANSACTIONS = "transactions";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

export default function SingleCollectorPage() {

    const navigate = useNavigate();

    const {wallet, section} = useParams();
    const [collector, setCollector] = useState<Collector>();
    const tabValue = useMemo(() => {
        return section === "transactions" ? 1 : 0;
    }, [section]);


    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        let newSection = TOKENS;
        if (newValue === 1) newSection = TRANSACTIONS;
        navigate(`/${SINGLE_COLLECTOR_PATH}${wallet}/${newSection}`)
    };

    useEffect(() => {

        if (wallet != null) {
            fetch(`${API_BASE_URL}${COLLECTORS_URL}?wallet=${wallet}`)
                .then(response => response.json())
                .then((response: ApiResponse<Collector[]>) => {
                    setCollector(snakeToCamelCase(response.data[0]));
                })
                .catch(e => console.error(e));
        }
    }, [wallet]);

    return (
        <>
            <Grid container spacing={3} height={'100%'} sx={{position: 'relative', overflow: 'hidden', height: '10px'}}>
                <ColorBoxes maxHeight={10}/>
            </Grid>
            <Card>
                <CardContent>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/">
                            Home
                        </Link>
                        <Link underline="hover" color="inherit" href={'/' + COLLECTORS_PATH}>
                            Collectors
                        </Link>
                        <Typography sx={{color: 'text.primary'}}>{wallet}</Typography>
                    </Breadcrumbs>
                    <Grid container spacing={2}>
                        <Grid size={{xs: 12, sm: 6, md: 4}}>
                            <Card variant="outlined" sx={{maxWidth: 360, marginTop: 2}}>
                                <Box sx={{paddingTop: 1, paddingLeft: 2, paddingRight: 2}}>
                                    <List sx={{paddingTop: 0}}>
                                        <ListItem sx={{paddingTop: 0, paddingBottom: 0}} disableGutters>
                                            <ListItemText primary={`Total tokens: `} sx={{width: "40px"}}/>
                                            <Typography sx={{flex: "2"}}>{collector?.totalTokens}</Typography>
                                        </ListItem>
                                        <ListItem sx={{paddingTop: 0, paddingBottom: 0}} disableGutters>
                                            <ListItemText primary={`Token value: `} sx={{width: "40px"}}/>
                                            <Typography
                                                sx={{flex: "2"}}>{formatEth(BigInt(collector?.totalValue || 0))}</Typography>
                                        </ListItem>
                                        <ListItem sx={{paddingTop: 0, paddingBottom: 0}} disableGutters>
                                            <ListItemText primary={`Domain: `} sx={{width: "40px"}}/>
                                            <Typography
                                                sx={{flex: "2"}}>{collector?.ethDomain || collector?.baseDomain || collector?.baseEthDomain || ''}</Typography>
                                        </ListItem>
                                    </List>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid size={{xs: 12, sm: 6, md: 8}}>

                            <Tabs value={tabValue} onChange={handleTabChange}>
                                <Tab label="Tokens" {...a11yProps(0)} />
                                <Tab label="Transactions" {...a11yProps(1)}  />
                            </Tabs>

                            <CustomTabPanel value={tabValue} index={0}>
                                <TokensTab wallet={wallet!}/>
                            </CustomTabPanel>
                            <CustomTabPanel value={tabValue} index={1}>
                                <TransactionsTab wallet={wallet!}/>
                            </CustomTabPanel>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
}
