import * as React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Layout from "./Layout";
import Homepage from "./features/home/Homepage";
import TokensPage from "./features/tokens/TokensPage";
import CollectorsPage from "./features/collectors/CollectorsPage";
import TransactionsPage from "./features/transactions/TransactionsPage";
import SingleTokenPage from "./features/singleToken/SingleTokenPage";
import {SINGLE_COLLECTOR_PATH, SINGLE_TOKEN_PATH} from "./utils/constants";
import SingleCollectorPage from "./features/singleCollector/SingleCollectorPage";

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Layout/>}>
                    <Route index element={<Homepage/>}/>
                    <Route path={`/tokens`} element={<TokensPage/>}/>
                    <Route path={`/collectors`} element={<CollectorsPage/>}/>
                    <Route path={`/${SINGLE_COLLECTOR_PATH}:wallet`} element={<SingleCollectorPage/>}/>
                    <Route path={`/${SINGLE_COLLECTOR_PATH}:wallet/:section`} element={<SingleCollectorPage/>}/>
                    <Route path={`/transactions`} element={<TransactionsPage/>}/>
                    <Route path={`/${SINGLE_TOKEN_PATH}:tokenId`} element={<SingleTokenPage/>}/>
                    <Route path={`/${SINGLE_TOKEN_PATH}:tokenId/:section`} element={<SingleTokenPage/>}/>
                    <Route path="*" element={<Navigate to="/" replace/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}