import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    CardContent,
    Chip,
    Divider,
    Stack,
    TextField,
    useMediaQuery
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import {useSearchParams} from "react-router-dom";
import ColorBoxes from "../ColorBoxes";
import {API_BASE_URL, TOKENS_URL, traits} from "../../utils/constants";
import {snakeToCamelCase} from "../../utils/helpers";
import {Token} from "../../models/token";
import Paper from "@mui/material/Paper";
import {ApiResponse} from "../../models/ApiResponse";
import {SelectedTraits} from "../../models/trait";
import TokensGallery from "../common/TokensGallery";
import {sanitizeSortModel} from "@mui/x-data-grid/hooks/features/sorting/gridSortingUtils";
import {GridSortModel} from "@mui/x-data-grid";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import theme from "../../theme";
import Spacer from "../Spacer";

export default function TokensPage() {
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [loadedTokens, setLoadedTokens] = useState<Token[]>();
    const [totalTokens, setTotalTokens] = useState(0);
    const [currentTreeId, setCurrentTreeId] = useState("");

    const paginationModel = useMemo<{ pageSize: number; page: number; }>(() => {
        return {
            pageSize: Number(searchParams.get('perPage')) || 25,
            page: parseInt(searchParams.get('page') || '0', 10),
        }
    }, [searchParams]);

    const selectedTraits = useMemo<SelectedTraits>(() => {
        return {
            paper: searchParams.get('paper'),
            margins: searchParams.get('margins'),
            format: searchParams.get('format'),
            background: searchParams.get('background'),
            tree_choice: searchParams.get('tree_choice'),
            texture: searchParams.get('texture'),
            mention: searchParams.get('mention'),
            bark: searchParams.get('bark'),
            thread_flow: searchParams.get('thread_flow'),
            thread: searchParams.get('thread'),
            tree_id: searchParams.get('tree_id'),
        }
    }, [searchParams]);

    const sortModel = useMemo<GridSortModel>(() => {
        const orderParam = searchParams.get('order_by');
        if (orderParam == null) return [];
        const order = orderParam.substring(0, 1) === '-' ? 'desc' : 'asc'
        return [{field: order === "asc" ? orderParam : orderParam.substring(1, orderParam.length), sort: order}];
    }, [searchParams]);

    useEffect(() => {
        setCurrentTreeId(searchParams.get("tree_id") || "")
    }, [searchParams]);

    useEffect(() => {
        function getTokensSearchParams() {
            let urlSearchParams = "";
            for (const [trait, val] of Object.entries(selectedTraits)) {
                if (val != null) {
                    urlSearchParams += `&${trait}=${encodeURIComponent(val)}`
                }
            }
            return urlSearchParams;
        }

        const offset = `&offset=${(paginationModel.page) * paginationModel.pageSize}`;
        setIsLoading(true);

        function getOrderByParam() {
            if (sortModel == null || sortModel.length == 0) return "";
            const orderBy = sortModel[0].sort === "asc" ? sortModel[0].field : "-" + sortModel[0].field;
            return `&order_by=${orderBy}`;
        }

        fetch(`${API_BASE_URL}${TOKENS_URL}?limit=${paginationModel.pageSize}${getOrderByParam()}${offset}${getTokensSearchParams()}`)
            .then(response => response.json())
            .then((response: ApiResponse<Token[]>) => {
                setTotalTokens(response.pagination.totalRowCount);
                setLoadedTokens(response.data.map(token => snakeToCamelCase(token)))
            })
            .catch(e => console.error(e)).finally(() => setIsLoading(false));
    }, [selectedTraits, paginationModel.page, paginationModel.pageSize]);

    const handleDeleteTrait = (trait: string, _traitToDelete: string) => () => {
        selectedTraits[trait as keyof typeof selectedTraits] = null;
        searchParams.delete(trait);
        searchParams.delete("page");
        setSearchParams(searchParams);
    };

    function selectTrait(trait: string, value: any) {
        selectedTraits[trait as keyof typeof selectedTraits] = value;
        searchParams.delete("page");
        searchParams.set(trait, String(value));
        setSearchParams(searchParams);
    }

    const handleTreeIdChange = (value: string) => {
        setCurrentTreeId(value);
    };

    const onTreeIdSubmit = () => {
        if (isNaN(parseInt(currentTreeId || ""))) {
            searchParams.delete("tree_id");
            setCurrentTreeId("")
        } else {
            searchParams.set("tree_id", String(currentTreeId));
        }
        setSearchParams(searchParams)
    };

    return (
        <>
            <Grid container spacing={3} height={'100%'} sx={{position: 'relative', overflow: 'hidden', height: '10px'}}>
                <ColorBoxes maxHeight={10}/>
            </Grid>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid size={{xs: 12, sm: 6, md: 4}}>
                            <Accordion defaultExpanded={!isMobile}>
                                <AccordionSummary
                                    sx={{minHeight: "0px", ".MuiAccordionSummary-content": {margin: 0},}}
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography gutterBottom variant="h6">
                                        Select traits to filter tokens
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack direction={"row"} sx={{paddingBottom: "10px"}}>
                                        <Typography variant={"h6"}>Tree id: </Typography>
                                        <Spacer/>
                                        <TextField
                                            id="tree-id-text-field"
                                            variant="standard"
                                            placeholder={"All"}
                                            value={currentTreeId}
                                            inputProps={{style: {fontSize: "1.3rem"}}}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTreeIdChange(event.target.value)}
                                            onKeyDown={(ev) => {
                                                if (ev.key === 'Enter') {
                                                    onTreeIdSubmit();
                                                    ev.preventDefault();
                                                }
                                            }}
                                        />
                                    </Stack>
                                    <Divider/>
                                    <Typography variant={"h6"}>Paper: </Typography>

                                    <Stack
                                        spacing={{xs: 1}}
                                        direction="row"
                                        useFlexGap
                                        sx={{flexWrap: 'wrap', paddingBottom: "10px"}}
                                    >
                                        {traits.paper.map(paper => {
                                            return (<Chip
                                                key={paper.value}
                                                label={`${paper.value} (${paper.occurrence})`}
                                                component="a"
                                                disabled={selectedTraits["paper"] == paper.value}
                                                variant="outlined"
                                                clickable
                                                onClick={() => selectTrait("paper", paper.value)}
                                            />)
                                        })}

                                    </Stack>

                                    <Typography variant={"h6"}>Margins: </Typography>

                                    <Stack
                                        spacing={{xs: 1}}
                                        direction="row"
                                        useFlexGap
                                        sx={{flexWrap: 'wrap', paddingBottom: "10px"}}
                                    >
                                        {traits.margins.map(margin => {
                                            return (<Chip
                                                key={margin.value}
                                                label={`${margin.value} (${margin.occurrence})`}
                                                component="a"
                                                disabled={selectedTraits["margins"] == margin.value}
                                                variant="outlined"
                                                clickable
                                                onClick={() => selectTrait("margins", margin.value)}
                                            />)
                                        })}
                                    </Stack>

                                    <Typography variant={"h6"}>Format: </Typography>

                                    <Stack
                                        spacing={{xs: 1}}
                                        direction="row"
                                        useFlexGap
                                        sx={{flexWrap: 'wrap', paddingBottom: "10px"}}
                                    >
                                        {traits.format.map(format => {
                                            return (<Chip
                                                key={format.value}
                                                label={`${format.value} (${format.occurrence})`}
                                                component="a"
                                                disabled={selectedTraits["format"] == format.value}
                                                variant="outlined"
                                                clickable
                                                onClick={() => selectTrait("format", format.value)}
                                            />)
                                        })}
                                    </Stack>

                                    <Typography variant={"h6"}>Background: </Typography>

                                    <Stack
                                        spacing={{xs: 1}}
                                        direction="row"
                                        useFlexGap
                                        sx={{flexWrap: 'wrap', paddingBottom: "10px"}}
                                    >
                                        {traits.background.map(background => {
                                            return (<Chip
                                                key={background.value}
                                                label={`${background.value} (${background.occurrence})`}
                                                component="a"
                                                disabled={selectedTraits["background"] == background.value}
                                                variant="outlined"
                                                clickable
                                                onClick={() => selectTrait("background", background.value)}
                                            />)
                                        })}
                                    </Stack>

                                    <Typography variant={"h6"}>Tree choice: </Typography>

                                    <Stack
                                        spacing={{xs: 1}}
                                        direction="row"
                                        useFlexGap
                                        sx={{flexWrap: 'wrap', paddingBottom: "10px"}}
                                    >
                                        {traits.tree_choice.map(treeChoice => {
                                            return (<Chip
                                                key={treeChoice.value}
                                                label={`${treeChoice.value} (${treeChoice.occurrence})`}
                                                component="a"
                                                disabled={selectedTraits["tree_choice"] == treeChoice.value}
                                                variant="outlined"
                                                clickable
                                                onClick={() => selectTrait("tree_choice", treeChoice.value)}
                                            />)
                                        })}
                                    </Stack>

                                    <Typography variant={"h6"}>Texture: </Typography>

                                    <Stack
                                        spacing={{xs: 1}}
                                        direction="row"
                                        useFlexGap
                                        sx={{flexWrap: 'wrap', paddingBottom: "10px"}}
                                    >
                                        {traits.texture.map(texture => {
                                            return (<Chip
                                                key={texture.value}
                                                label={`${texture.value} (${texture.occurrence})`}
                                                component="a"
                                                disabled={selectedTraits["texture"] == texture.value}
                                                variant="outlined"
                                                clickable
                                                onClick={() => selectTrait("texture", texture.value)}
                                            />)
                                        })}
                                    </Stack>

                                    <Typography variant={"h6"}>Mention: </Typography>

                                    <Stack
                                        spacing={{xs: 1}}
                                        direction="row"
                                        useFlexGap
                                        sx={{flexWrap: 'wrap', paddingBottom: "10px"}}
                                    >
                                        {traits.mention.map(mention => {
                                            return (<Chip
                                                key={mention.value}
                                                label={`${mention.value} (${mention.occurrence})`}
                                                component="a"
                                                disabled={selectedTraits["mention"] == mention.value}
                                                variant="outlined"
                                                clickable
                                                onClick={() => selectTrait("mention", mention.value)}
                                            />)
                                        })}
                                    </Stack>

                                    <Typography variant={"h6"}>Bark: </Typography>

                                    <Stack
                                        spacing={{xs: 1}}
                                        direction="row"
                                        useFlexGap
                                        sx={{flexWrap: 'wrap', paddingBottom: "10px"}}
                                    >
                                        {traits.bark.map(bark => {
                                            return (<Chip
                                                key={bark.value}
                                                label={`${bark.value} (${bark.occurrence})`}
                                                component="a"
                                                disabled={selectedTraits["bark"] == bark.value}
                                                variant="outlined"
                                                clickable
                                                onClick={() => selectTrait("bark", bark.value)}
                                            />)
                                        })}
                                    </Stack>

                                    <Typography variant={"h6"}>Thread Flow: </Typography>

                                    <Stack
                                        spacing={{xs: 1}}
                                        direction="row"
                                        useFlexGap
                                        sx={{flexWrap: 'wrap', paddingBottom: "10px"}}
                                    >
                                        {traits.thread_flow.map(flow => {
                                            return (<Chip
                                                key={flow.value}
                                                label={`${flow.value} (${flow.occurrence})`}
                                                component="a"
                                                disabled={selectedTraits["thread_flow"] == flow.value}
                                                variant="outlined"
                                                clickable
                                                onClick={() => selectTrait("thread_flow", flow.value)}
                                            />)
                                        })}
                                    </Stack>

                                    <Typography variant={"h6"}>Thread: </Typography>

                                    <Stack
                                        spacing={{xs: 1}}
                                        direction="row"
                                        useFlexGap
                                        sx={{flexWrap: 'wrap', paddingBottom: "10px"}}
                                    >
                                        {traits.thread.map(thread => {
                                            return (<Chip
                                                key={thread.value}
                                                label={`${thread.value} (${thread.occurrence})`}
                                                component="a"
                                                disabled={selectedTraits["thread"] == thread.value}
                                                variant="outlined"
                                                clickable
                                                onClick={() => selectTrait("thread", thread.value)}
                                            />)
                                        })}
                                    </Stack>

                                </AccordionDetails>
                            </Accordion>

                        </Grid>
                        <Grid size={{xs: 12, sm: 6, md: 8}}>
                            <Stack
                                direction={{md: 'column', lg: 'row'}}
                                spacing={{xs: 1, sm: 2, md: 4}}
                                alignItems={"center"}
                                width={"100%"}
                            >

                                {Object.entries(selectedTraits).some((trait) => trait[1] != null) && <Paper
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        flexWrap: 'wrap',
                                        listStyle: 'none',
                                        flexGrow: 1,
                                        p: 0.5,
                                        m: 0,
                                    }}
                                >
                                    {Object.entries(selectedTraits).map(([trait, value]) => {
                                        if (value != null) return (<Chip
                                            key={value}
                                            label={value}
                                            onDelete={handleDeleteTrait(trait, value)}
                                        />)
                                    })}
                                </Paper>}
                            </Stack>
                            {(!isLoading && loadedTokens != null) &&
                                <TokensGallery tokens={loadedTokens}
                                               sortModel={sanitizeSortModel(sortModel, true)}
                                               totalTokens={totalTokens}
                                               paginationModel={paginationModel}/>}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
        ;
}
