import * as React from "react";
import {TablePagination} from "@mui/material";
import {DataGrid, GridCallbackDetails, GridColDef, GridSortModel, GridValidRowModel} from '@mui/x-data-grid';
import {useSearchParams} from "react-router-dom";
import {TableData} from "../../models/transaction";


interface TransactionTableProps<T extends GridValidRowModel> {
    columns: GridColDef<T, any, any>[];
    data: T[];
    sortModel: GridSortModel | undefined;
    paginationModel: {
        pageSize: number;
        page: number;
    };
    isLoading: boolean;
    totalRowCount: number;
}

export default function PaginatedTable<T extends TableData>(props: TransactionTableProps<T>) {
    const [searchParams, setSearchParams] = useSearchParams();

    function onPaginationModelChange(model: { pageSize: number; page: number; }) {
        if (props.paginationModel.pageSize != model.pageSize) {
            searchParams.set("perPage", String(model.pageSize));
            searchParams.delete("page");
        }
        if (model.page > 0) {
            searchParams.set("page", String(model.page));
        } else {
            searchParams.delete("page")
        }
        setSearchParams(searchParams);
    }

    function onSortModelChange(model: GridSortModel, _details: GridCallbackDetails) {
        if (model.length === 0) {
            searchParams.delete("order_by");
        } else {
            searchParams.set("order_by", model[0].sort === "asc" ? model[0].field : "-" + model[0].field);
        }
        searchParams.delete("page");
        setSearchParams(searchParams);
    }

    return (
        <>
            <TablePagination
                component="div"
                count={props.totalRowCount}
                page={props.paginationModel.page}
                onPageChange={(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => onPaginationModelChange({
                    ...props.paginationModel,
                    page: newPage
                })}
                rowsPerPage={props.paginationModel.pageSize}
                onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    onPaginationModelChange({pageSize: Number(event.target.value), page: 0})
                }}
                rowsPerPageOptions={[25, 50, 100]}
            />

            {/*the div prevents datagrid from taking all vertical space*/}
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <DataGrid
                    getRowId={(row) => row.id}
                    rows={props.data}
                    columns={props.columns}
                    paginationModel={props.paginationModel}
                    paginationMode="server"
                    onPaginationModelChange={onPaginationModelChange}
                    sortModel={props.sortModel}
                    initialState={{
                        sorting: {
                            sortModel: props.sortModel,
                        },

                        columns: {
                            columnVisibilityModel: {
                                id: false,
                            },
                        },
                        pagination: {rowCount: -1, paginationModel: {pageSize: 25}}
                    }}
                    loading={props.isLoading}
                    pageSizeOptions={[25, 50, 100]}
                    rowCount={props.totalRowCount}
                    disableRowSelectionOnClick
                    checkboxSelection={false}
                    onSortModelChange={onSortModelChange}
                    sortingMode={"server"}
                />
            </div>
        </>
    );
}
